import React, { useEffect } from 'react';
import ContentArea from '../Shared/ContentArea/ContentArea';
import RecruitingPageModel from './Models/RecruitingPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { media } from '@glitz/core';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import { styled, theme } from '../Theme';
import Grid from '../Shared/Grid/Grid';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import { StyleOrStyleArray } from '@glitz/type';
import { Helmet } from 'react-helmet';

function RecruitingPage() {
  const {
    pageHeading,
    pageIntroText,
    teaserImage,
    contentArea,
    inEditMode,
    bodyText,
    breadcrumb,
  } = useCurrentPage<RecruitingPageModel>();

  const imageAreaBackgroundStyle: StyleOrStyleArray = {
    backgroundImage: teaserImage && `url(${teaserImage.src})`,
  };

  return (
    <Main>
      {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />}
      <TopContent>
        <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
          {pageHeading}
        </Heading>
        <Intro {...applyEditModeAttr(inEditMode && 'IntroText')}>
          {pageIntroText}
        </Intro>
        {teaserImage && (
          <TeaserImageWrapper>
            <TeaserImage css={imageAreaBackgroundStyle} />
          </TeaserImageWrapper>
        )}
        <Helmet>
          <script
            src={`https://roiworkspace.se/api/v1/portal/portal2.js/?guid=6ee01f00-bdb7-11ed-a244-6fa417787277&regions=city,state,country&affiliates=1&categories=1`}
          />
        </Helmet>
        <div id="roi-portal"></div>
        <BodyText
          {...applyEditModeAttr(inEditMode && 'BodyText')}
          dangerouslySetInnerHTML={{
            __html: bodyText,
          }}
        />
      </TopContent>
      <Grid
        type={12}
        data-content-area
        {...applyEditModeAttr(inEditMode && 'MainContent')}
      >
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </Grid>
    </Main>
  );
}

const Main = styled.main({
  backgroundColor: theme.white,
});

const TopContent = styled.div({
  margin: { y: theme.spacing(8), x: 'auto' },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: theme.medium,
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { y: theme.greater },
    padding: { x: theme.medium, y: theme.none },
    maxWidth: theme.maxWidthContentPage,
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { y: theme.theta, x: 'auto' },
  }),
});

const TeaserImageWrapper = styled.div({
  margin: { y: theme.spacing(10) },
  ...media(theme.mediaQuery.mediaMinHuge, {
    marginLeft: `calc((${theme.contentMaxWidth}px - 100vw) / 2)`,
    marginRight: `calc((${theme.contentMaxWidth}px - 100vw) / 2)`,
    margin: { y: theme.spacing(15) },
  }),
});

const TeaserImage = styled.div({
  width: '100%',
  minHeight: theme.bannerBlockMobileImageHeight,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '900px',
    minHeight: theme.bannerBlockDesktopImageHeight,
    margin: { x: 'auto' },
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    minHeight: theme.teaserImageDesktopHeight,
  }),
});

const Heading = styled.h1({
  maxWidth: theme.maxWidthSmall,
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  color: theme.black,
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.theta },
    marginTop: theme.vast,
  }),
});

const Intro = styled.span({
  font: { size: theme.epsilon },
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.epsilon,
  }),
});

const BodyText = styled.div({
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.normalWide,
  font: { size: theme.moreLarge },
  color: theme.black,
  marginTop: theme.spacing(5),
});

export default RecruitingPage;
